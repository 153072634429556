import axios from 'axios';

const API_URL = 'http://programmapp.ru/api/auth/jwt/';

class AuthService {
  async login(user) {
    const payload = 'username=' + user.username + '&password=' + user.password
    await axios
      .post(API_URL + 'login', payload)
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user',JSON.stringify(user.username));
          localStorage.setItem('token', JSON.stringify(response.data));
        }
        return response.data;
      });

  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('project');
  }
 
}

export default new AuthService();