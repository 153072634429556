<template>
    <b-navbar justified toggleable="lg" type="dark" variant="info">
    <b-navbar-brand href="\"> {{this.project_name}} </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/esntable">ESN check</b-nav-item>
        <b-nav-item href="/customeresn">ESN available</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ms-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>{{ this.user }}</em>
          </template>
          <b-dropdown-item @click.prevent="logout">Выйти</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
name: "MainLayout",
data() {
    return {
      user:'',
      project_name:''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
async mounted() {
  if (this.loggedIn == true) {
    this.user = localStorage.getItem('user')
    if (localStorage.getItem('name') == undefined) {
    window.addEventListener('name-key-localstorage-changed', (event) => {
    this.project_name = event.detail.storage;
  });} else {
  this.project_name = localStorage.getItem('name')
  }
this.$router.push("/");
    
    
   } else {
      this.$router.push("/login");
    }
 
},
methods: {
 
  async logout() {
      try {
        await this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      } catch (e) {

      }

    },
  },
};
</script>

