import authHeader from '/src/services/auth-header';
import axios from 'axios'

const API_URL = 'http://programmapp.ru/';

export const data = {
  namespaced: true,
  actions: {
    async getUserContent() {
    
        return await axios.get(API_URL + 'user',{ headers: authHeader() }).then(response => {
            localStorage.setItem('project',response.data.project_id)
            return response.data.project_id
            
        }).catch(error => {
        
            return error
            
        })
    },
    async getProjectContent({commit}, project_id) {
    
        return await axios.get(API_URL + 'programm/project?board_project='+ project_id,{ headers: authHeader() }).then(response => {
            localStorage.setItem('name',response.data[0].name)
            return response.data[0].name
            
        }).catch(error => {
        
            return error
            
        })
    },
    async getUserBoard(project_id) {
    
        return await axios.get(API_URL + 'programm/board?board_project='+ project_id,{ headers: authHeader() }).then(response => {
        
            return response
            
        }).catch(error => {
        
            return error
            
        })
    },
    getESN(project_id) {
    
        return axios.get('/api').then(response => {
        
            return response
            
        }).catch(error => {
        
            return error
            
        })
    },
    getCustomerESN(project_id) {
    
        return axios.get('/api').then(response => {
        
            return response
            
        }).catch(error => {
        
            return error
            
        })
    }

  }
};