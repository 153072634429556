<template>
    <b-row>
    </b-row>
</template>

<script>
export default {
name: "EmptyLayout",
};
</script>


