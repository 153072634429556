<template>
  <div id="app">
    <component :is='layout'>

    </component>
    <router-view/>
</div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from '@/layouts/EmptyLayout'
export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  components: {
    MainLayout, EmptyLayout
  }
}
</script>
